
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "a0c6cdd0-3b6d-4ec4-97ff-e3a82e7600e7"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
